import React from "react";
import { Heading, Flex } from "primitives";
import { PassageBookerHeaderActions } from "./PassageBookerHeaderActions";

export const PassageBookerHeader = (props: any) => {
  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" mb={4} mx={3}>
        <Heading display={1}>Passage Booker</Heading>
        <PassageBookerHeaderActions {...props} />
      </Flex>
    </>
  );
};
