import {
  Execution,
  ScriptInput,
  Script,
  ScriptListItem,
  CreateScriptCommand,
  EditScriptCommand,
  ExecuteScriptCommand,
  ScriptExecutionListItem
} from "../models";
import { scriptDataProvider } from "app/network/dataProvider";
import { OperationType } from "app/network/models/constants";

type DataProvider = (
  type: OperationType,
  resource: string,
  params?: object
) => Promise<any>;

export class ScriptExecutionService {
  private readonly dataProvider: DataProvider;
  private static instance: ScriptExecutionService;

  static default(): ScriptExecutionService {
    if (ScriptExecutionService.instance) {
      return ScriptExecutionService.instance;
    } else {
      return ScriptExecutionService.newInstance(scriptDataProvider);
    }
  }

  static newInstance(dataProvider: DataProvider): ScriptExecutionService {
    ScriptExecutionService.instance = new ScriptExecutionService(dataProvider);
    return ScriptExecutionService.instance;
  }

  private constructor(dataProvider: DataProvider) {
    this.dataProvider = dataProvider;
  }

  async fetchRunningScripts(): Promise<ScriptExecutionListItem[]> {
    const value = await this.dataProvider(
      OperationType.GET_LIST,
      "scriptExecution"
    );
    return value.data;
  }

  async executeScript(cmd: ExecuteScriptCommand): Promise<Execution> {
    const scriptExecution = await this.dataProvider(
      OperationType.CREATE,
      "scriptExecution",
      {
        data: cmd
      }
    );
    return scriptExecution.data;
  }

  async getExecution(executionId: string): Promise<Execution> {
    const value = await this.dataProvider(
      OperationType.GET_ONE,
      "scriptExecution",
      {
        id: executionId
      }
    );
    return value.data;
  }

  async stopScriptExecution(executionId: string): Promise<Execution> {
    await this.dataProvider(OperationType.DELETE, "scriptExecution", {
      id: executionId
    });
    return this.getExecution(executionId);
  }

  async sendData(executionId: string, data: ScriptInput): Promise<Execution> {
    await this.dataProvider(
      OperationType.UPDATE_ONE,
      `scriptExecution/${executionId}/input`,
      { data: { body: data } }
    );
    return this.getExecution(executionId);
  }

  async getScripts(): Promise<ScriptListItem[]> {
    const scripts = await this.dataProvider(OperationType.GET_LIST, "script");
    return scripts.data;
  }
}

export const createScript = async (
  data: CreateScriptCommand
): Promise<Script> => {
  const response: any = await scriptDataProvider(
    OperationType.CREATE,
    `script`,
    {
      data: {
        name: data.name,
        script: data.script,
        resources: data.resources
      }
    }
  );
  return response.data;
};

export const fetchScript = async (id: string): Promise<Script> => {
  const response: any = await scriptDataProvider(
    OperationType.GET_ONE,
    `script`,
    {
      id
    }
  );
  return response.data;
};

export const editScript = async (
  id: string,
  data: EditScriptCommand
): Promise<Script> => {
  // Remove unwanted property from resources object
  const newResource =
    data.resources &&
    data.resources.map((resource) => {
      return { id: resource.id, targetLocation: resource.targetLocation };
    });

  const response: any = await scriptDataProvider(
    OperationType.UPDATE,
    `script`,
    {
      id,
      data: {
        name: data.name,
        script: data.script,
        resources: newResource
      }
    }
  );

  return response.data;
};

export const deleteScript = async (id: number) => {
  await scriptDataProvider(OperationType.DELETE, `script`, {
    id
  });
};
