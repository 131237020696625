import React from "react";
import { Switch, Route } from "react-router-dom";
import { PassageBooker } from "./components/PassageBooker";

export const PassageBookerRoutes = () => (
  <>
    <Switch>
      <Route path="/passage-booker" exact component={PassageBooker} />
    </Switch>
  </>
);
