import { Dispatch } from "react";
import { FETCH_PASSAGE_SUCCESS } from "../models/actions";
import { getPassagesListForBooking } from "../services";

export const getPassagesForBooking = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const payload = await getPassagesListForBooking(data);
      dispatch({
        type: FETCH_PASSAGE_SUCCESS,
        payload
      });
      return Promise.resolve(payload);
    } catch (e) {
      return Promise.resolve([]);
    }
  };
};
