import React, { Component } from "react";
import { Box, Heading, Flex } from "primitives";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  AddButton
} from "components";
import { Link } from "react-router-dom";
import { EditButton } from "components";
import { PaginationSimple } from "app/shared";
import { SuspenseQuery, Suspense } from "app/network";
import { connect } from "react-redux";
import { GroundStationConfiguration } from "../models";
import { SatelliteInstance } from "app/satellite/models/index";
import { fetchGroundStationConfigListAction } from "../actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { GroundStationConfigDeleteContainer } from "./GroundStationConfigDelete";
import { isSatelliteOnOrganization, getSatelliteName } from "app/shared/utils";

interface GroundStationConfigListState {
  pageSize: number;
  page: number;
  total: number;
}

interface GroundStationConfigListProps {
  satellites: any;
  fetchGroundStationsConfig: () => Promise<GroundStationConfiguration[]>;
}

export class GroundStationConfigList extends Component<
  GroundStationConfigListProps,
  GroundStationConfigListState
> {
  state = {
    pageSize: 25,
    page: 0,
    total: 0
  };

  filterGroundStationConfigs(
    groundStationConfigs: GroundStationConfiguration[],
    satellites: SatelliteInstance
  ) {
    if (!Array.isArray(groundStationConfigs)) return [];
    const { pageSize, page, total } = this.state;
    const minIndex = page * pageSize;
    const maxIndex = minIndex + pageSize;
    const result: GroundStationConfiguration[] = groundStationConfigs.filter(
      (groundStationConfig) => {
        return (
          satellites &&
          isSatelliteOnOrganization(groundStationConfig.satelliteID, satellites)
            .length
        );
      }
    );
    if (result.length !== total) {
      this.setState({ total: result.length });
    }
    return result.slice(minIndex, maxIndex);
  }

  render() {
    const { satellites, fetchGroundStationsConfig } = this.props;
    const { pageSize, page, total } = this.state;

    return (
      <Suspense>
        <SuspenseQuery query={fetchGroundStationsConfig}>
          {({ response, reload }) => {
            console.log("TURBO ~ render ~ response:", response);
            const groundStationConfigs = this.filterGroundStationConfigs(
              response,
              satellites
            );
            return (
              <Box mx={3}>
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <Heading display={1}>Ground Stations Configurations</Heading>
                  <Link to="/groundstations/create">
                    <AddButton>Create</AddButton>
                  </Link>
                </Flex>
                <Table>
                  <TableHead>
                    <TableRow bg="fill.0">
                      <TableCell width="auto">Satellite</TableCell>
                      <TableCell width="auto">Ground Station</TableCell>
                      <TableCell width="auto">Provider</TableCell>
                      <TableCell width="50" />
                      <TableCell width="50" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groundStationConfigs.map(
                      (
                        groundStationConfig: GroundStationConfiguration,
                        index: number
                      ) => {
                        const satelliteName =
                          satellites &&
                          getSatelliteName(
                            groundStationConfig.satelliteID,
                            satellites
                          );

                        return (
                          <TableRow
                            key={`${groundStationConfig.groundStationID}-${index}`}
                          >
                            <TableCell>{satelliteName}</TableCell>
                            <TableCell>
                              {groundStationConfig.groundStationName}
                            </TableCell>
                            <TableCell>
                              {groundStationConfig.providerName}
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/configurations/${groundStationConfig.satelliteID}/${groundStationConfig.groundStationID}`}
                              >
                                <EditButton>Edit</EditButton>
                              </Link>
                            </TableCell>
                            <TableCell>
                              <GroundStationConfigDeleteContainer
                                groundStationConfig={groundStationConfig}
                                satelliteName={satelliteName}
                                onChange={() => reload()}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
                <PaginationSimple
                  total={total}
                  page={page + 1}
                  pageSize={pageSize}
                  nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
                  previousPageUrl={page - 1}
                  onChange={(newPage: number | string) => {
                    this.setState({
                      page: Number(newPage),
                      pageSize
                    });
                  }}
                  onPageSizeChange={(newPageSize: number) =>
                    this.setState({
                      page: 0,
                      pageSize: newPageSize
                    })
                  }
                />
              </Box>
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    satellites:
      state.constellations.selected &&
      state.constellations.selected.satelliteInstances
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  fetchGroundStationsConfig: () =>
    dispatch(fetchGroundStationConfigListAction())
});

export const GroundStationConfigListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroundStationConfigList);
