import { createGlobalStyle } from "styled-components";
import { themeGet } from "styled-system";

export const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Anonymous+Pro:400,700');

    * {
      box-sizing: border-box;
    }

    html {
      /* Adjust font size */
      font-size: 100%;
      -webkit-text-size-adjust: 100%;
      /* Font varient */
      font-variant-ligatures: none;
      -webkit-font-variant-ligatures: none;
      /* Smoothing */
      /* text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
      -webkit-font-smoothing: antialiased; */
    }

    html, body, #app-root, #app-root > div {
      width: 100%;
      height: 100%;
      width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 0;
      font-family: ${themeGet("fonts.0")};
      overflow: hidden;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      opacity: 1;
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: inner-spin-button !important;
      width: 19px;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }

    .panel-danger {
      border-color: #DB4444;
    }

    .panel {
      padding: 10px;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 4px;
      -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
      box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }

    .panel-danger>.panel-heading {
      color: #DB4444;
      background-color: #1F1E2C;
      border-color: #DB4444;
      padding: 5px;
    }

    .panel-title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 16px;
      color: inherit;
    }

    .panel > .list-group {
      margin-bottom: 0;
      list-style: none;
    }

    .list-group {
      padding-left: 0;
      margin-bottom: 20px;
    }

    .text-danger {
      color: #DB4444;
    }

    .error-detail {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 13px;
      color: #DB4444
    }

    fieldset {
      border: none;
      padding: 0 !important;
      margin: 0;
    }

    .rst__rowLabel {
      flex: 1 1 auto !important;
      padding-right: 0 !important;
    }

    .hidden {
      display: none;
    }

    /* Custom ScrollBar*/

    ::-webkit-scrollbar {
        background-color: transparent;
        width: 3px;
        height: 3px;
        position: absolute !important;
    }

    ::-webkit-scrollbar-track {
        background-color: rgba(250,250,250,0.1);
    }

    ::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        height: 50px;
    }

    ::-webkit-scrollbar-button {
      display:none
    }

    ::-webkit-scrollbar-corner {
      display:none
    }
`;
