import React from "react";
import { Button, Icon, Text, Flex } from "primitives";
import { ButtonProps } from "primitives/Button";
import { DATA_TEST_ID_ATTR_NAME } from "components/testable/models";

const AddButton = ({ children, ...props }: ButtonProps) => (
  <Button size="small" variant="outline" {...props}>
    <Icon name="Add" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

AddButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "AddButton"
};

const EditButton = ({ children, ...props }: ButtonProps) => (
  <Button size="small" variant="default" {...props}>
    <Flex cursor="pointer" alignItems="center">
      <Icon name="Edit" size={12} />
      {children && <Text ml={2}>{children}</Text>}
    </Flex>
  </Button>
);

EditButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "EditButton",
  title: "Edit"
};

const SaveButton = ({ children, ...props }: ButtonProps) => (
  <Button size="small" variant="default" {...props}>
    <Icon name="Save" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

SaveButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "SaveButton"
};

const DeleteButton = ({ children, size, ...props }: ButtonProps) => (
  <Button size="small" variant="danger" {...props}>
    <Icon name="Delete" size={size} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

DeleteButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "DeleteButton",
  size: 12,
  title: "Delete"
};

const CancelButton = ({ children, ...props }: ButtonProps) => (
  <Button size="small" variant="cancel" {...props}>
    <Text>{children}</Text>
  </Button>
);

CancelButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "CancelButton"
};

const CopyButton = ({ children, ...props }: ButtonProps) => (
  <Button
    size="small"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Icon name="Copy" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

CopyButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "CopyButton",
  title: "Copy"
};

const PasteButton = ({ children, ...props }: ButtonProps) => (
  <Button
    size="small"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Icon name="Paste" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

PasteButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "PasteButton"
};

const ConfigButton = ({ children, ...props }: ButtonProps) => (
  <Button
    size="small"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Icon name="SettingsMenu" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

ConfigButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "PasteButton"
};

const DownloadButton = ({ children, ...props }: ButtonProps) => (
  <Button
    size="small"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Icon name="Download" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

DownloadButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "DownloadButton"
};

const UploadButton = ({ children, ...props }: ButtonProps) => (
  <Button
    size="small"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Icon name="Upload" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

UploadButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "UploadButton"
};

const BackButton = ({ children, ...props }: ButtonProps) => (
  <Button
    size="small"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Icon name="ArrowLeft" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

BackButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "BackButton"
};

const PlayButton = ({ children, size, ...props }: ButtonProps) => (
  <Button
    size="small"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Icon name="Play" size={size} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

PlayButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "Play",
  size: 12
};

const PauseButton = ({ children, size, ...props }: ButtonProps) => (
  <Button
    size="small"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Icon name="Pause" size={size} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

PauseButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "Pause",
  size: 12
};

const SendButton = ({ children, ...props }: ButtonProps) => (
  <Button
    size="small"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Icon name="Send" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

SendButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "SendButton",
  title: "Send",
  height: 25,
  width: 33,
  ml: 2
};

const ExportButton = ({ children, ...props }: ButtonProps) => (
  <Button
    size="smaller"
    variant="default"
    hoverBackgroundColor={props.backgroundColor}
    {...props}
  >
    <Text>Export</Text>
  </Button>
);

ExportButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "ExportButton",
  title: "Export",
  width: 55
};

const CommentButton = ({ children, ...props }: ButtonProps) => (
  <Button size="small" variant="default" {...props}>
    <Icon name="StackComment" size={12} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

CommentButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "CommentButton",
  title: "Add a comment to selected TC Stack"
};

const ActivateButton = ({ children, size, ...props }: ButtonProps) => (
  <Button size="small" variant="default" {...props}>
    <Icon name="Play" size={size} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

ActivateButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "ActivateButton",
  size: 12
};
const DeactivateButton = ({ children, size, ...props }: ButtonProps) => (
  <Button size="small" variant="default" {...props}>
    <Icon name="Stop" size={size} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

DeactivateButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "DeactivateButton",
  size: 12
};

const SuspendButton = ({ children, size, ...props }: ButtonProps) => (
  <Button size="small" variant="default" {...props}>
    <Icon name="Pause" size={size} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

SuspendButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "SuspendButton",
  size: 12
};

const CreateButton = ({ children, size, ...props }: ButtonProps) => (
  <Button size="small" variant="default" {...props}>
    <Icon name="NewFile" size={size} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

CreateButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "CreateButton",
  size: 12
};

const UndoButton = ({ children, size, ...props }: ButtonProps) => (
  <Button size="small" variant="default" {...props}>
    <Icon name="Undo" size={size} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

UndoButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "UndoButton",
  size: 12
};

const ResetButton = ({ children, size, ...props }: ButtonProps) => (
  <Button size="small" variant="default" {...props}>
    <Icon name="Cancel" size={size} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

ResetButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "ResetButton",
  size: 12
};

const PointerButton = ({ children, size, ...props }: ButtonProps) => (
  <Button size="small" variant="default" {...props}>
    <Icon name="Pointer" size={size} />
    {children && <Text ml={2}>{children}</Text>}
  </Button>
);

PointerButton.defaultProps = {
  [DATA_TEST_ID_ATTR_NAME]: "PointerButton",
  size: 12
};

export {
  AddButton,
  EditButton,
  SaveButton,
  DeleteButton,
  CancelButton,
  CopyButton,
  PasteButton,
  ConfigButton,
  DownloadButton,
  UploadButton,
  BackButton,
  PauseButton,
  PlayButton,
  SendButton,
  ExportButton,
  CommentButton,
  ActivateButton,
  DeactivateButton,
  SuspendButton,
  CreateButton,
  UndoButton,
  ResetButton,
  PointerButton
};
