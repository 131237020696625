import React, { useContext } from "react";
import { Flex } from "primitives";
import {
  TimelineCommandsProps,
  CalendarTimeline,
  TimelineTypes,
  ContextActionsNames
} from "app/planningSystem/models";
import {
  SelectSimple as Select,
  SaveButton,
  UndoButton,
  TooltipMenu,
  PointerButton
} from "components";
import { PlanningSystemContext } from "app/planningSystem/context";

export const TimelineCommands = ({
  availableSatellites,
  calendarTimeline,
  handleTimelineSelection,
  changeButtonsDisabled,
  handleUndoTimelineChanges,
  handleResetTimelinePosition
}: TimelineCommandsProps) => {
  const {
    dispatch,
    state: { selectedTimeline, selectedSatellites }
  } = useContext(PlanningSystemContext);

  const timelineIsEditable =
    selectedTimeline?.timelineType !== TimelineTypes.MASTER;

  const setSelectedSatellites = (selectedSat: string[]) =>
    dispatch({
      type: ContextActionsNames.selectedSatellites,
      payload: selectedSat
    });

  return (
    <Flex justifyContent={"space-between"} mb={4} overflow={"visible"}>
      <Select onChange={handleTimelineSelection}>
        {calendarTimeline.map((opt: CalendarTimeline, i: number) => (
          <option key={i} value={opt.timelineType}>
            {opt.name}
          </option>
        ))}
      </Select>

      <div>
        {timelineIsEditable && (
          <>
            <SaveButton
              title={"Save timeline"}
              mr={2}
              disabled={changeButtonsDisabled}
            />
            <UndoButton
              title={"Undo changes"}
              mr={2}
              disabled={changeButtonsDisabled}
              onClick={handleUndoTimelineChanges}
            />
          </>
        )}
        <PointerButton
          title={"Reset timeline position"}
          onClick={handleResetTimelinePosition}
          size={12}
        />
        <TooltipMenu
          selected={selectedSatellites}
          type={"checklist"}
          options={availableSatellites.map((sat) => ({
            id: sat.id.toString(),
            name: sat.label
          }))}
          title={"Satellites"}
          onSelect={setSelectedSatellites}
        />
      </div>
    </Flex>
  );
};
