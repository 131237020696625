import React, { createContext } from "react";
import { IContext, IContextActions, ContextActionsNames } from "../models";
import { getTimelineTimeWindow } from "app/planningSystem/utils/helpers";

export const initialState: IContext = {
  navigationDrawerVisible: false,
  operationActivityDialog: null,
  contextMenu: null,
  timelineItemDialog: null,
  selectedTimeline: null,
  selectedSatellites: [],
  timelineWindow: getTimelineTimeWindow()
};

export const reducer = (state: IContext, action: IContextActions): IContext => {
  switch (action.type) {
    case ContextActionsNames.navigationDrawerVisible:
      return {
        ...state,
        navigationDrawerVisible:
          action.payload as IContext["navigationDrawerVisible"]
      };
    case ContextActionsNames.operationActivityDialog:
      return {
        ...state,
        operationActivityDialog:
          action.payload as IContext["operationActivityDialog"]
      };
    case ContextActionsNames.contextMenu:
      return {
        ...state,
        contextMenu: action.payload as IContext["contextMenu"]
      };
    case ContextActionsNames.timelineItemDialog:
      return {
        ...state,
        timelineItemDialog: action.payload as IContext["timelineItemDialog"]
      };
    case ContextActionsNames.selectedTimeline:
      return {
        ...state,
        selectedTimeline: action.payload as IContext["selectedTimeline"]
      };
    case ContextActionsNames.selectedSatellites:
      return {
        ...state,
        selectedSatellites: action.payload as IContext["selectedSatellites"]
      };
    case ContextActionsNames.timelineWindow:
      return {
        ...state,
        timelineWindow: action.payload as IContext["timelineWindow"]
      };
    default:
      return { ...state, ...(action.payload as any) };
  }
};

export const PlanningSystemContext = createContext<{
  state: IContext;
  dispatch: React.Dispatch<IContextActions>;
}>({ state: initialState, dispatch: () => null });
