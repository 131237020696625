import React, { useContext, useEffect } from "react";
import {
  TimelineItemRendererProps,
  ContextActionsNames
} from "app/planningSystem/models";
import { PlanningSystemContext } from "app/planningSystem/context";
import { useContextMenu } from "hooks";
import { TimelineItemContextMenu } from "./TimelineItemContextMenu";

export const TimelineItemRenderer = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps
}: TimelineItemRendererProps) => {
  const { dispatch } = useContext(PlanningSystemContext);
  const { contextMenu, handleContextMenu } = useContextMenu({
    data: item,
    menuTemplate: TimelineItemContextMenu
  });

  useEffect(() => {
    dispatch({ type: ContextActionsNames.contextMenu, payload: contextMenu });
  }, [contextMenu]);

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const itemProps = { ...getItemProps(item.itemProps) };

  const itemStyles = {
    ...itemProps.style,
    cursor: "e-resize"
  };

  return (
    <div {...{ ...itemProps, style: { ...itemStyles } }}>
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

      <div
        onContextMenu={handleContextMenu}
        className="rct-item-content"
        style={{
          maxHeight: `${itemContext.dimensions.height}`,
          cursor: "move",
          width: "100%"
        }}
      >
        {itemContext.title}
      </div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
    </div>
  );
};
