import {
  Colors,
  AlertDTO,
  Severity,
  AlertValueStatus,
  AlertValue,
  AlertDetails,
  CreateAlertDefinitionCommand,
  AlertConfig,
  SchemaErrors,
  AlertType
} from "app/alert/models";
import { searchDataSourceById } from "app/shared/tree/utils";
import { ReadingDTO, ValueDTO } from "app/telemetry/models";
import { DataSource, Reading } from "app/telemetry/models/datasources";
import { createSelector } from "reselect";
import { getDataSourceLabels } from "../services";

/*
 *Get the most priority alert status color from a given reading
 */
export const getAlertColor = (
  reading: ReadingDTO | AlertDTO | Reading,
  defaultColor: string = Colors.Default
) => {
  if (!reading || !reading.value) return defaultColor;
  let alertType = "NormalValue";
  reading.value.forEach((value: any) => {
    if (!value.alert) {
      return null;
    }
    const alert = value.alert;
    if (alert.type === "OutOfBoundsValue") {
      alertType = "OutOfBounds";
    }
    if (alertType === "NormalValue") {
      if (alert.type === AlertValueStatus.AlertValue) {
        alertType = alert.severity;
      }
    } else {
      if (
        alert.type === AlertValueStatus.AlertValue &&
        alertType !== "OutOfBounds"
      ) {
        alertType = alertType === Severity.Warning ? alert.severity : alertType;
      }
    }
  });
  if (alertType === Severity.Warning || alertType === Severity.Critical) {
    return Colors[alertType];
  } else if (alertType === "NormalValue") {
    return defaultColor;
  } else {
    return Colors.OutOfBounds;
  }
};

/*
 *Get the most priority alert status color from a given reading (array type value)
 *Used in alert table.
 */
export const getAlertColorForArrayValue = (
  reading: ReadingDTO | AlertDTO,
  ignoreValuesWithoutAlert = false,
  defaultColor: string = Colors.Default
) => {
  if (reading && reading.value) {
    const arrayValue = reading.value;
    let colors: string[] = [];
    let index = 0;
    arrayValue.forEach((value: ValueDTO | AlertValue, i: number) => {
      /*eslint no-empty: "error"*/
      if (
        ignoreValuesWithoutAlert &&
        value &&
        value.alert &&
        value.alert.type === "NormalValue"
      ) {
        // empty
      } else {
        if (value && value.alert) {
          const alert = value.alert;
          if (!alert) {
            colors[index] = defaultColor;
          } else if (
            alert &&
            alert.type === AlertValueStatus.AlertValue &&
            alert.severity
          ) {
            colors[index] = Colors[alert.severity];
          } else if (
            alert &&
            alert.type === AlertValueStatus.OutOfBoundsValue
          ) {
            colors[index] = Colors.OutOfBounds;
          } else {
            colors[index] = defaultColor;
          }
        } else {
          colors[index] = defaultColor;
        }
      }
      index += 1;
    });
    //Remove empty values from array
    colors = colors.filter((color) => {
      return color;
    });
    return colors;
  }
  return defaultColor;
};

/*
 *Generate and return the alert text description.
 *Used in alert table.
 *Format Example: Out Of Bounds: 15 -> [-10, 10]
 */
export const getAlertDetails = (alert: AlertDTO) => {
  if (alert.type === AlertType.DynamicAlert) {
    return alert.dynamicAlertsDetails;
  }
  if (alert && alert.value && alert.value.length > 0) {
    const valueAlerts = alert.value;
    let details = "";
    valueAlerts.forEach((valueAlertAux) => {
      const valueAlert = valueAlertAux.alert;
      if (
        valueAlert.type === AlertValueStatus.AlertValue ||
        valueAlert.type === AlertValueStatus.OutOfBoundsValue
      ) {
        const values =
          valueAlert.type === AlertValueStatus.AlertValue
            ? `[${valueAlert.lowerThreshold};${valueAlert.upperThreshold}]`
            : `[${valueAlert.minValue};${valueAlert.maxValue}]`;
        details += `${
          valueAlert.type === AlertValueStatus.AlertValue
            ? valueAlert.severity
            : "Out Of Bounds"
        }: ${valueAlert.index !== null ? `[${valueAlert.index}]` : ""} ${
          (valueAlert.index || valueAlert.index === 0) &&
          Array.isArray(alert.currentValue)
            ? alert.currentValue[valueAlert.index]
            : alert.currentValue
        } ->  ${values},`;
      }
    });
    return details;
  }
  return null;
};

/**
 *Generate and return the alert value text description.
 *Used in graph widget.
 *Format Example: Out Of Bounds: 15 -> [-10, 10]
 */
export const getAlertStatus = (alert: AlertDTO) => {
  if (alert.type === AlertType.DynamicAlert) {
    return alert.dynamicAlertsDetails
      ?.map((d) => d.alertLevel?.severity)
      .join(",");
  }
  if (alert && alert.value && alert.value.length > 0) {
    const status: string[] = [];
    alert.value.forEach((value: AlertValue) => {
      const valueAlert = value.alert;
      if (valueAlert && valueAlert.type === AlertValueStatus.AlertValue) {
        if (valueAlert.severity && status.indexOf(valueAlert.severity) === -1) {
          return status.push(valueAlert.severity);
        }
      } else if (
        valueAlert &&
        valueAlert.type === AlertValueStatus.OutOfBoundsValue
      ) {
        if (status.indexOf("Out Of Bounds") === -1) {
          return status.push("Out Of Bounds");
        }
      }
    });
    return status.join(",");
  } else {
    return "";
  }
};

/**
 *Generate and return the alert value text description.
 *Used in graph widget.
 *Format Example: Out Of Bounds: 15 -> [-10, 10]
 */
export const getAlertValueDetails = (alert: AlertDetails, value: number) => {
  let details = "";
  if (alert) {
    const values =
      alert.type === AlertValueStatus.AlertValue
        ? `[${alert.lowerThreshold};${alert.upperThreshold}]`
        : `[${alert.minValue};${alert.maxValue}]`;
    details += `${
      alert.type === AlertValueStatus.AlertValue
        ? alert.severity
        : "Out Of Bounds"
    }: ${value} ->  ${values},`;
  } else {
    details = `${value}`;
  }
  return details;
};

/**
 *Return color for a given alert value.
 *Used in graph widget.
 */
export const getAlertValueColor = (alert: any) => {
  const prefix = "colors.";
  if (alert.type === AlertValueStatus.OutOfBoundsValue) {
    return prefix + Colors.OutOfBounds;
  }
  if (alert.type === AlertValueStatus.NormalValue) {
    return prefix + Colors.Default;
  }
  if (alert.type === AlertValueStatus.AlertValue && alert.severity) {
    return prefix + Colors[alert.severity as keyof typeof Colors];
  }
};

export const handleImportedDefinitions = (alertDefinitions: any[]) => {
  const alertDefinitionsTransformed: any = [];
  if (Array.isArray(alertDefinitions)) {
    alertDefinitions.map((def) => {
      const _alertDefinitions = {
        alertType: def.alert.alertType,
        dataSourceId: def.dataSourceId,
        dataSource: def.dataSourceId,
        alertConfigs: def.alert.alertConfigs,
        metadata: {}
      };
      alertDefinitionsTransformed.push(_alertDefinitions);
    });
  }
  return alertDefinitionsTransformed;
};

const datasourceMap = new Map();
export const getDatasourcesSelector = createSelector(
  [
    (state: any) =>
      getDataSourceLabels(state.satellite.dashboard.satelliteDefinition)
  ],
  (datasources: any) => {
    datasources.map((ds: any) => datasourceMap.set(ds.id, ds.name));
    return datasourceMap;
  }
);

export const keywords = [
  "AND",
  "OR",
  ">",
  "<",
  "===",
  "!==",
  "=",
  "==",
  "!=",
  "<=",
  ">="
];

export const checkUndefined = (conditions?: string[]) => {
  if (!conditions) return;
  return conditions?.some((c) => c === undefined);
};

export const isValidExpression = (conditions?: string[]) => {
  if (!conditions) return;
  return conditions?.some((c) =>
    keywords.some((kw) => c.toUpperCase().includes(kw))
  );
};

export const conditionContainsMainDS = (
  conditions?: string[],
  mainDatasource?: string
) => {
  if (!conditions || !mainDatasource) return;
  return conditions?.every((c) => {
    return c?.includes(mainDatasource);
  });
};

export function alertConditionValidator(
  formData: CreateAlertDefinitionCommand,
  errors: SchemaErrors,
  datasources: DataSource[]
) {
  if (!formData || !datasources) return;
  const datasourcesList = datasources.map((ds: any) => ds.name);
  const mainDatasource = datasources.find(
    (ds: any) => ds.id === formData.dataSourceId
  )?.name;
  const conditions = formData?.alertConfigs?.map(
    (ac: AlertConfig) => ac.condition
  );

  if (!conditionContainsMainDS(conditions, mainDatasource)) {
    errors?.alertConfigs.addError(`condition must contain main datasource`);
  }

  if (checkUndefined(conditions) || !isValidExpression(conditions)) {
    errors?.alertConfigs.addError(`condition is not valid or undefined`);
  }

  const datasourcesInCondition =
    extractDatasourceNamesFromCondition(conditions);

  const { invalidDatasources, validDatasources } = validateDatasourceNames(
    datasourcesInCondition,
    datasourcesList
  );

  if (invalidDatasources.length) {
    errors?.alertConfigs.addError(
      `${invalidDatasources.map((ds) => ds)} is not a valid datasource`
    );
  }

  const indexValidations = validateDSIndexesInCondition(
    validDatasources,
    datasources
  );

  const invalidIndexes = Object.keys(indexValidations)
    .filter((key) => !indexValidations[key].valid)
    .map((key) => indexValidations[key]);

  const applyToAllIndexes = formData.alertConfigs?.map(
    (ac) => ac.applyToAllIndexes
  );
  const shouldSkipIndexesValidation = !applyToAllIndexes?.some(Boolean);

  if (invalidIndexes.length > 0 && shouldSkipIndexesValidation) {
    errors?.alertConfigs.addError(`${invalidIndexes.map((ds) => ds.message)}`);
  }

  return errors;
}

export function extractDatasourceNamesFromCondition(conditions?: string[]) {
  if (!conditions) return [];

  // datasource names pattern e.g adu1_4_out
  const regex = /\b[a-zA-Z_][a-zA-Z0-9_]*\b(?:\[(?:\d+)\])?/g;
  let match;
  const _datasourceNames = [];

  while ((match = regex.exec(conditions as any)) !== null) {
    _datasourceNames.push(match[0]);
  }

  const datasourceNames = _datasourceNames.filter(
    (name) => !keywords.includes(name.toUpperCase())
  );

  return datasourceNames;
}

export function validateDatasourceNames(
  datasourcesInCondition: string[],
  datasourcesList: string[]
) {
  const validDatasources = datasourcesInCondition.filter(
    (datasourceName: string) =>
      datasourcesList.includes(datasourceName?.match(/^(\w+)/)?.[1] || "")
  );

  const invalidDatasources = datasourcesInCondition.filter(
    (datasourceName: string) =>
      !datasourcesList.includes(datasourceName?.match(/^(\w+)/)?.[1] || "")
  );
  return { validDatasources, invalidDatasources };
}

export function validateDSIndexesInCondition(
  validDatasources: string[],
  datasources: DataSource[]
) {
  const indexValidations: any = {};
  validDatasources.forEach((ds) => {
    if (ds.includes("[") && ds.includes("]")) {
      const [dsName, dsIndex] = ds.match(/^(\w+)\[(\d+)\]/)?.slice(1) || [];
      const datasource = datasources.find((_ds) => _ds.name.includes(dsName));
      const isIndexedDS = datasource?.tmDataType?.arraySize;
      if (isIndexedDS) {
        const isValidIndex = Number(dsIndex) <= isIndexedDS;
        indexValidations[dsName] = {
          valid: isValidIndex,
          message:
            !isValidIndex && `${dsIndex} is not a valid index on ${dsName}`
        };
      } else {
        indexValidations[dsName] = {
          valid: false,
          message: `${dsName} has no indexes`
        };
      }
    }
  });
  return indexValidations;
}

export const transformAlertDefPayload = (
  alertDefinitions: any[],
  exportOptions: any
) => {
  const doFilter = (alertDefinition: any) =>
    exportOptions.datasourceName === "ALL"
      ? alertDefinitions
      : alertDefinition.datasourceName === exportOptions.datasourceName;

  const formatted = alertDefinitions.filter(doFilter).map((alertDef) => {
    return {
      ...alertDef,
      alert: {
        ...alertDef.alert,
        metadata: {}
      }
    };
  });

  return { alertDefinitions: formatted };
};

export const injectDatasourceNames = (alertDefinitions: any[], satDef: any) => {
  if (!alertDefinitions && !satDef) {
    console.warn(`alert definition or sat definition is not defined`);
    return [];
  }

  const withDSNames = alertDefinitions.map((alertDef) => {
    const datasource = searchDataSourceById(
      satDef.systems,
      alertDef.dataSourceId
    );
    delete alertDef.dataSourceId;
    delete alertDef.id;
    return {
      datasourceName: datasource?.name,
      fullyQualifiedName: datasource?.fullyQualifiedName,
      ...alertDef
    };
  });
  return withDSNames;
};

export const mapDSNamesToIDs = (alertDefinitions: any[], satDef: any) => {
  if (!alertDefinitions && !satDef) {
    console.warn(`alert definition or sat definition is not defined`);
    return [];
  }

  const withDSIDs = alertDefinitions.map((alertDef) => {
    const datasource = searchDataSourceById(
      satDef.systems,
      alertDef.fullyQualifiedName
    );
    delete alertDef.fullyQualifiedName;
    delete alertDef.datasourceName;
    return {
      dataSourceId: datasource?.id,
      ...alertDef
    };
  });
  return withDSIDs;
};

export function checkIfExisting(
  alertDefinitions: any[],
  alertDefList: any[],
  idx: any
) {
  const existingAlertDef = alertDefinitions.filter(
    (d: any) => d.dataSourceId == alertDefList[idx]?.dataSourceId
  );
  const id = existingAlertDef[0]?.dataSourceId;

  const existingDef = id === alertDefList[idx].dataSourceId;
  return {
    shouldUpdate: existingDef,
    id: id
  };
}

export enum ACTION {
  CREATED = "created",
  UPDATED = "updated"
}
