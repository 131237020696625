import React from "react";
import AceEditor from "react-ace";
import { Box } from "primitives";

import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/snippets/python";
import "./PythonEditorTheme";

interface PythonEditorProps {
  id?: string;
  onChange: (value: string) => void;
  value: string | undefined;
  height?: string;
  width?: string;
  fontSize?: number;
  tabSize?: number;
}

const PythonEditor = (props: PythonEditorProps) => {
  const { id, value, onChange, height, width, fontSize, tabSize } = props;
  return (
    <Box my={2}>
      <AceEditor
        mode="python"
        theme="aurora"
        width={width}
        height={height}
        name={id}
        onChange={(changedValue) => onChange(changedValue)}
        fontSize={fontSize}
        tabSize={tabSize}
        showGutter={true}
        highlightActiveLine={true}
        value={value}
        enableBasicAutocompletion={true}
        enableLiveAutocompletion={true}
        enableSnippets={true}
      />
    </Box>
  );
};

PythonEditor.displayName = "PythonEditor";

PythonEditor.defaultProps = {
  height: "380px",
  width: "100%",
  fontSize: 14,
  tabSize: 2
};

export default PythonEditor;
