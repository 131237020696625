import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex } from "primitives";
import { Form } from "app/shared";
import {
  bookerConfigUISchema,
  bookerConfigSchemaCreate
} from "app/passageBooker/models/schema";
import { fetchGroundStationsAction as gs } from "app/groundStation/actions";
import { getPassagesForBooking } from "app/passageBooker/actions";

export const BookerConfigForm = (props: any) => {
  const dispatch = useDispatch();
  const satellites = useSelector((s: any) => s.constellations.dashboard);
  useEffect(() => {
    dispatch(gs());
  }, []);

  const groundStations = useSelector(
    (s: any) => s.groundStations?.groundStations
  );
  const validator = (formData: any, errors: any) => {
    return errors;
  };

  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    formData: any
  ) => {
    e.preventDefault();
    dispatch(getPassagesForBooking(formData));
    props.closeModal();
  };

  const transformData = (formData: any) => {
    if (formData.daterange?.from && formData.daterange?.to) {
      formData.daterange.from = new Date(
        formData?.daterange?.from
      ).toISOString();
      formData.daterange.to = new Date(formData?.daterange?.to).toISOString();
    }
    return formData;
  };

  const onChange = useCallback((form) => {
    transformData(form.formData);
  }, []);

  const formData = {};

  return (
    <Flex flexDirection="column" bg="fill.0" p={5}>
      <Form
        ref={props.formRef}
        id="passage-booker"
        validate={(formData: any, errors: any) => validator(formData, errors)}
        formData={formData}
        schema={bookerConfigSchemaCreate(satellites, groundStations)}
        uiSchema={bookerConfigUISchema}
        disabled={false}
        onSubmit={(
          form: { formData: any },
          e: React.FormEvent<HTMLInputElement>
        ) => {
          return submit(e, form.formData);
        }}
        onChange={(form: any) => onChange(form)}
      >
        <> </>
      </Form>
    </Flex>
  );
};
