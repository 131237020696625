import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Flex } from "primitives";
import { TimelineRenderer } from "components";
import { Zoom, passageGroup } from "app/planningSystem/utils/constants";
import { isEqual } from "lodash";
import { TimelineCommands } from "./TimelineCommands";
import { TimelineItemRenderer } from "./TimelineItemRenderer";
import {
  TimelineDataState,
  CalendarTimeline,
  CalendarTimelineItem,
  ContextActionsNames,
  TimelineTypes,
  PlanningSystemTimelineProps
} from "app/planningSystem/models";
import { PlanningSystemContext } from "app/planningSystem/context";
import { getTimelineTimeWindow } from "app/planningSystem/utils/helpers";

const TimelineRoot = styled("div")``;

export const PlanningSystemTimeline = ({
  availableSatellites = [],
  calendarTimeline = [],
  passages,
  dataProvider
}: PlanningSystemTimelineProps) => {
  const {
    dispatch,
    state: { selectedTimeline, selectedSatellites, timelineWindow }
  } = useContext(PlanningSystemContext);
  const { startTime, endTime } = timelineWindow;

  const [timelineData, setTimelineData] = useState<TimelineDataState | null>(
    null
  );
  const [items, setItems] = useState<CalendarTimelineItem[]>([]);
  const [changeButtonsDisabled, setChangeButtonsDisabled] =
    useState<boolean>(true);

  useEffect(() => {
    /**
     * CALL ORDER
     * 1. When `calendarTimeline` is evaluated
     * 2. Selecting satellites passages are populated
     * 3. When all satellites are de-selected
     */
    if (calendarTimeline.length > 0) {
      if (selectedSatellites.length === 0) {
        return handleTimelineComposition();
      }
      handleTimelineComposition();
    }
  }, [calendarTimeline, passages, selectedSatellites]);

  useEffect(() => {
    if (selectedTimeline) {
      const { items, groups } = selectedTimeline;
      setTimelineData({ items, groups });
    }
  }, [selectedTimeline?.items, selectedTimeline?.groups]);

  useEffect(() => {
    setItems(timelineData?.items || []);
  }, [timelineData]);

  useEffect(() => {
    if (isEqual(items, initialItems)) {
      return setChangeButtonsDisabled(true);
    }

    setChangeButtonsDisabled(false);
  }, [items]);

  const { items: initialItems, groups } = timelineData || {
    items: [],
    groups: []
  };

  /**
   * Compose the timeline items for the selected timeline
   * assembling the passages and the operation-activities using `joinCalendarTimelinePassages`
   */
  const handleTimelineComposition = () => {
    const timeline =
      (selectedTimeline &&
        calendarTimeline.find((t) => t.uuid === selectedTimeline.uuid)) ||
      calendarTimeline.find(
        (t: CalendarTimeline) => t.timelineType === TimelineTypes.MASTER
      );
    if (timeline) {
      dispatch({
        type: ContextActionsNames.selectedTimeline,
        payload: joinCalendarTimelinePassages(timeline)
      });
    }
  };

  /**
   * @description Handle the item move in the timeline updating the state of `items`
   * @param itemId
   * @param dragTime
   * @param newGroupOrder
   */
  const handleItemMove = (itemId: any, dragTime: any, newGroupOrder: any) => {
    const group = groups[newGroupOrder];

    setItems(
      items.map((item: any) =>
        item.id === itemId
          ? Object.assign({}, item, {
              start_time: dragTime,
              end_time: dragTime + (item.end_time - item.start_time),
              group: group.id
            })
          : item
      )
    );

    console.log("Moved", itemId, dragTime, newGroupOrder);
  };

  /**
   * @description Handle the item resize in the timeline updating the state of `items`
   * @param itemId
   * @param time
   * @param edge
   */
  const handleItemResize = (itemId: any, time: any, edge: any) => {
    setItems(
      items.map((item: any) =>
        item.id === itemId
          ? Object.assign({}, item, {
              start_time: edge === "left" ? time : item.start_time,
              end_time: edge === "left" ? item.end_time : time
            })
          : item
      )
    );

    console.log("Resized", itemId, time, edge);
  };

  const handleUndoTimelineChanges = () => {
    setItems(initialItems);
  };

  /**
   * Bring the timeline position to the initial state
   */
  const handleResetTimelinePosition = async () => {
    const { startTime, endTime } = getTimelineTimeWindow();
    await setItems([]);
    await dataProvider(startTime, endTime);
    await setTimeout(() => setItems(initialItems));
  };

  /**
   * @description If there are selected satellites and passages in the store it joins
   * the passages to the selected calendarTimeline
   * @param calendarTimeline
   * @returns
   */
  const joinCalendarTimelinePassages = (calendarTimeline: CalendarTimeline) => {
    const joinedtimeline = {
      ...calendarTimeline,
      items: [
        ...calendarTimeline.items,
        ...(selectedSatellites.length > 0 ? passages : [])
      ],
      groups:
        (passages.length > 0 && [
          ...calendarTimeline.groups,
          ...(selectedSatellites.length > 0 ? [passageGroup] : [])
        ]) ||
        calendarTimeline.groups
    };
    return joinedtimeline;
  };

  /**
   * @description Handle the selection through the timeline
   * @param e
   */
  const handleTimelineSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = calendarTimeline.find(
      (t: CalendarTimeline) => t.timelineType === e.target.value
    );
    if (selected) {
      dispatch({
        type: ContextActionsNames.selectedTimeline,
        payload: joinCalendarTimelinePassages(selected)
      });
    }
  };

  /**
   * TODO: avoid re-rendering
   */
  return (
    <TimelineRoot data-testid={"timeline-root"}>
      <TimelineCommands
        availableSatellites={availableSatellites}
        calendarTimeline={calendarTimeline}
        handleTimelineSelection={handleTimelineSelection}
        changeButtonsDisabled={changeButtonsDisabled}
        handleUndoTimelineChanges={handleUndoTimelineChanges}
        handleResetTimelinePosition={handleResetTimelinePosition}
      />
      {(items.length === 0 && <Flex>{"Loading the timeline..."}</Flex>) || (
        <TimelineRenderer
          groups={groups}
          items={items}
          itemTouchSendsClick={false}
          stackItems={true}
          defaultTimeStart={startTime}
          defaultTimeEnd={endTime}
          onItemMove={handleItemMove}
          onItemResize={handleItemResize}
          minZoom={Zoom.min}
          maxZoom={Zoom.max}
          itemRenderer={(props) => <TimelineItemRenderer {...props} />}
          minResizeWidth={1}
          dataProvider={dataProvider}
        />
      )}
    </TimelineRoot>
  );
};
