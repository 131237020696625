import { Passage } from "../models";
import {
  FETCH_PASSAGE_ERROR,
  FETCH_PASSAGE_START,
  FETCH_PASSAGE_SUCCESS
} from "../models/actions";

const initialState: Passage[] = [];

export const passageBookerReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case FETCH_PASSAGE_ERROR:
    case FETCH_PASSAGE_START:
      return initialState;
    case FETCH_PASSAGE_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
};
