import React, { useState } from "react";
import { Box } from "primitives";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import { Link } from "react-router-dom";
import { PaginationSimple } from "app/shared";
import { EditButton, DeleteButton } from "components";
import { Passage } from "../models";
import { mockPassages } from "../mocks";
import { useSelector } from "react-redux";

export const PassageBookerTable = () => {
  const passagesList = useSelector((s: any) => s.passageBooker);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(10);
  const [total, setTotal] = useState(mockPassages.length);
  const passages = passagesList;
  return (
    <>
      <Box
        data-testid="passage-booker-list"
        className="passage-booker-list"
        mx={3}
      >
        <Table>
          <TableHead>
            <TableRow bg="fill.0">
              <TableCell width="10">Id</TableCell>
              <TableCell width="auto">Start</TableCell>
              <TableCell width="auto">End</TableCell>
              <TableCell width="auto">GS</TableCell>
              <TableCell width="auto">Max Elevation</TableCell>
              <TableCell width="auto">Satellite</TableCell>
              <TableCell width="auto">Provider</TableCell>
              <TableCell width="auto">Editable</TableCell>
              <TableCell width="auto">Priority</TableCell>
              <TableCell width="auto">Optimal</TableCell>
              <TableCell width="auto">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passages.map((passage: Passage, idx: number) => {
              return (
                <TableRow key={passage.passageID}>
                  <TableCell width="2%">
                    <Link
                      to={{ pathname: `/edit-passage`, state: { passage } }}
                    ></Link>
                    {passage.passageID}
                  </TableCell>
                  <TableCell width="1%">
                    {new Date(passage.start_time).toLocaleString()}
                  </TableCell>
                  <TableCell width="auto">
                    {new Date(passage.end_time).toLocaleString()}
                  </TableCell>
                  <TableCell>{passage.gs}</TableCell>
                  <TableCell>{passage.max_el}</TableCell>
                  <TableCell align="center">{passage.sat}</TableCell>
                  <TableCell align="center" width="4%">
                    {passage.provider_info?.provider?.toUpperCase()}
                  </TableCell>
                  <TableCell align="center">{passage.is_editable}</TableCell>
                  <TableCell align="center">{passage.priority}</TableCell>
                  <TableCell align="center">{passage.is_optimal}</TableCell>
                  <TableCell align="center">
                    {idx % 2 === 0 ? "UNBOOKED" : "BOOKED"}{" "}
                  </TableCell>
                  <TableCell>
                    <Link to={`#`}>
                      {idx % 2 === 0 ? (
                        <EditButton disabled={false}>BOOK </EditButton>
                      ) : (
                        <DeleteButton>CANCEL</DeleteButton>
                      )}
                    </Link>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <PaginationSimple
          total={total}
          page={page + 1}
          pageSize={pageSize}
          nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
          previousPageUrl={page - 1}
          onChange={(newPage: string | number) => {
            setPage(Number(newPage));
            setPageSize(pageSize);
          }}
          onPageSizeChange={(newPageSize: number) => {
            setPage(0);
            setPageSize(Number(newPageSize));
          }}
        />
      </Box>
    </>
  );
};
