import { dataProvider, DELETE, CREATE, UPDATE, GET_LIST } from "app/network";

import { mockPassages } from "../mocks";
// TLE
export const createTLE = (data: any) => {
  return dataProvider(CREATE, `passages`, data).then(() => {
    return data;
  });
};

export const updateTLE = (data: any) => {
  return dataProvider(UPDATE, `passages`, data).then(() => {
    return data;
  });
};

export const deleteTLE = (data: any) => {
  return dataProvider(DELETE, `passages`, data).then(() => {
    return data;
  });
};

export const getTLE = (data: any) => {
  return dataProvider(GET_LIST, `passages`, data).then(() => {
    return data;
  });
};

// BOOKING
export const getPassagesListForBooking = (data: any) => {
  const filteredPassages = filterPassages(mockPassages, data);
  return filteredPassages;
  return dataProvider(GET_LIST, `passages`, data).then(() => {
    return data;
  });
};

export const bookingSinglePassage = (data: any) => {
  return dataProvider(CREATE, `passages`, data).then(() => {
    return data;
  });
};

export const cancelSinglePassageBooker = (data: any) => {
  return dataProvider(CREATE, `passages`, data).then(() => {
    return data;
  });
};

export const cancelMultiplePassageBooker = (data: any) => {
  return dataProvider(CREATE, `passages`, data).then(() => {
    return data;
  });
};

export const bookingMultiplePassages = (data: any) => {
  return dataProvider(UPDATE, `passages`, data).then(() => {
    return data;
  });
};

export const updatePassage = (data: any) => {
  return dataProvider(UPDATE, `passages`, data).then(() => {
    return data;
  });
};

export const updateConfig = (data: any) => {
  return dataProvider(UPDATE, `passages`, data).then(() => {
    return data;
  });
};

function filterPassages(passages: any[], filters: any) {
  const { groundStationsForEachSatellite: filterCriteria, daterange } = filters;
  const filteredPassages = passages.filter((passage) => {
    return filterCriteria.some(
      (criteria: { groundStations: string | any[]; satellite: any }) =>
        criteria.groundStations.includes(passage.gs) &&
        criteria.satellite === passage.sat &&
        new Date(daterange.from) <= new Date(passage.end_time) &&
        new Date(daterange.to) >= new Date(passage.start_time)
    );
  });
  const sortedPassages = filteredPassages.sort(
    (a, b) =>
      new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
  );
  return sortedPassages;
}
