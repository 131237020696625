import React from "react";
import { PassageBookerTable } from "./PassageBookerTable";
import { PassageBookerHeader } from "./PassageBookerHeader";

export const PassageBooker = (props: any) => {
  return (
    <>
      <PassageBookerHeader {...props} />
      <PassageBookerTable />
    </>
  );
};
