import { SelectBox, InputBase } from "app/shared";
import { DateTimePicker } from "components";
type Satellite = any;
type GroundStation = any;

export const bookerConfigSchemaCreate = (
  satellites: Satellite[],
  groundStations: GroundStation[]
) => {
  return {
    $schema: "http://json-schema.org/draft-07/schema#",
    $id: "passages.schema.json",
    title: "Fetch Passages based on following configuration",
    name: "Fetch Passages",
    description:
      "The configuration options to book passages from various providers",
    type: "object",
    definitions: {
      time: {
        $id: "#/definitions/time",
        title: "Time",
        type: "string"
      }
    },
    properties: {
      daterange: {
        title: "Select a date range for fetching passages",
        type: "object",
        properties: {
          from: {
            $id: "#/properties/daterange/properties/from",
            title: "From",
            $ref: "#/definitions/time"
          },
          to: {
            $id: "#/properties/daterange/properties/to",
            title: "To",
            $ref: "#/definitions/time"
          }
        }
      },
      groundStationsForEachSatellite: {
        type: "array",
        hasRemove: true,
        title: "Select GroundStations for each satellite",
        items: {
          type: "object",
          properties: {
            satellite: {
              title: "Satellite",
              type: "string",
              enum: satellites.map((satellite: Satellite) => satellite.label)
            },
            groundStations: {
              type: "array",
              title: "Ground Stations",
              items: {
                type: "string",
                enum: groundStations.map(
                  (gs: GroundStation) => gs.groundStationName
                ),
                uniqueItems: true
              },
              uniqueItems: true
            }
          }
        }
      },
      additionalData: {
        title: "Add additional config options",
        type: "object",
        properties: {
          config: {
            type: "string",
            title: " "
          }
        }
      }
    }
  };
};

export const bookerConfigDefault = {};

export const bookerConfigUISchema = {
  daterange: {
    classNames: "daterange-passage-booker",
    from: {
      "ui:widget": DateTimePicker,
      classNames: "daterange-passage-booker-from"
    },
    to: {
      "ui:widget": DateTimePicker,
      classNames: "daterange-passage-booker-to"
    }
  },
  groundStationsForEachSatellite: {
    items: {
      satellite: { classNames: "multi-select-sats", "ui:widget": SelectBox },
      groundStations: {
        "ui:widget": "checkboxes",
        classNames: "multi-select-gs"
      }
    }
  },
  additionalData: {
    config: {
      "ui:widget": InputBase
    }
  }
};
