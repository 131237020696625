import React, { useCallback, useRef } from "react";
import { Flex } from "primitives";
import { AddButton, SaveButton, CancelButton } from "components";
import { Dialog, DialogTitle, DialogActions, Box } from "primitives";
import useToggle from "components/toggler/hooks/useToggle";
import { BookerConfigForm } from "./forms/PassageBookerConfigForm";
import { UploadTLEForm } from "./forms/UploadTLEForm";

export const PassageBookerHeaderActions = () => {
  return (
    <Flex>
      <BookerConfig />
    </Flex>
  );
};

export const BookerConfig = () => {
  const formRef = useRef(null);
  const defaultToggled = false;
  const [toggled, onToggle] = useToggle({
    defaultToggled: defaultToggled ? defaultToggled : false
  });

  const submit = useCallback(() => {
    console.log("submit");
  }, []);
  const cancel = useCallback(() => {
    onToggle();
  }, []);

  return (
    <>
      <AddButton size={"small"} onClick={onToggle}>
        {"BOOKER CONFIGURATION"}
      </AddButton>
      <Dialog open={toggled} maxWidth="md" height="100%">
        <DialogTitle>Fetch Passages from Providers</DialogTitle>
        <Flex height="100%">
          <Box overflow="scroll" width="100%">
            <BookerConfigForm formRef={formRef} closeModal={cancel} />
          </Box>
        </Flex>
        <DialogActions>
          <Flex justifyItems="flex-end" alignItems="center">
            <SaveButton
              mr={1}
              type="submit"
              id="passage-booker"
              form="passage-booker"
            >
              Run
            </SaveButton>
            <SaveButton mr={1} onClick={submit}>
              Save
            </SaveButton>
            <CancelButton onClick={cancel} mx={1}>
              Cancel
            </CancelButton>
          </Flex>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const UploadTLE = () => {
  const defaultToggled = false;
  const [toggled, onToggle] = useToggle({
    defaultToggled: defaultToggled ? defaultToggled : false
  });

  const submit = useCallback(() => {
    console.log("submit");
  }, []);
  const cancel = useCallback(() => {
    onToggle();
  }, []);

  return (
    <>
      <AddButton size={"small"} onClick={onToggle}>
        {"Upload TLE"}
      </AddButton>
      <Dialog open={toggled} maxWidth="md" height="100%">
        <DialogTitle>Upload TLE from file</DialogTitle>
        <Flex height="100%">
          <Box overflow="hidden !important" width="65%">
            <UploadTLEForm />
          </Box>
        </Flex>
        <DialogActions>
          <Flex justifyItems="flex-end" alignItems="center">
            <SaveButton mr={1} onClick={submit}>
              Submit
            </SaveButton>
            <CancelButton onClick={cancel} mx={1}>
              Cancel
            </CancelButton>
          </Flex>
        </DialogActions>
      </Dialog>
    </>
  );
};
