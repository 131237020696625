import { InputField } from "components";

export const uploadTLEUISchema = {
  tle: { "ui:field": InputField },
  "ui:submitButtonOptions": {
    norender: true
  }
};

export const uploadTLEDefault = {
  tle: ""
};

export const uploadTLESchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $id: "tle.schema.json",
  title: "Upload TLE",
  name: "Upload TLE",
  description: "The configuration options to upload TLE",
  type: "object",
  properties: {
    tle: {
      type: "string",
      title: "tle",
      default: []
    }
  },
  required: ["tle"]
};
