import {
  FetchTimelineParams,
  FetchTimelineResponse
} from "app/planningSystem/models";
import { planningSystemDataProvider } from "app/network/dataProvider";
import { OperationType } from "app/network/models/constants";
import { metadata, oaUUID } from "app/planningSystem/utils/mocks";
import { generateTimelines } from "app/planningSystem/utils/helpers";

/**
 * GET
 */
export const fetchTimeline = async (
  params: FetchTimelineParams
): Promise<FetchTimelineResponse | null> => {
  try {
    const response = await planningSystemDataProvider(
      OperationType.GET_LIST,
      `timeline/`,
      { filter: params }
    );
    // TODO: Remove the mocked timelines once the service will be ready
    const timelineResponse = {
      timelines: generateTimelines(oaUUID, params.startTime, params.endTime), // 100 entries
      metadata
    };
    return (
      (response.data && response.data.timelines?.length > 0 && response.data) ||
      timelineResponse
    );
  } catch (error) {
    console.log(error);
    return null;
  }
};
