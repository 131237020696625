import React, { useState, useEffect } from "react";
import { CancelButton, SaveButton } from "components";
import { DialogContent, Flex } from "primitives";
import { Form } from "app/shared";
import {
  generateOaHandleSchema,
  OperationActivityHandleUISchema
} from "app/planningSystem/schemas";
import { SuspenseMutation } from "app/network";
import {
  DialogContentHandleProps,
  OaHandleState,
  ISODateString,
  OperationDialogActionName,
  CreateOaParams,
  PatchOaParams
} from "app/planningSystem/models";
import { GroundStation } from "app/groundStation";
import { fetchGroundStationBySatellite } from "app/groundStation/services";
import {
  handleRequestBody,
  operationToFormState,
  handleFormValidator
} from "app/planningSystem/utils/helpers";
import { Procedure } from "app/procedure/models";
import { Resource } from "app/resources/models";

export const DialogContentHandle = ({
  handleClose,
  satelliteInstances,
  getProcedures,
  getResources,
  createOperationActivity,
  editOperationActivity,
  getAllOperationActivities,
  operation,
  handleType
}: DialogContentHandleProps) => {
  const [formDataState, setFormDataState] = useState<OaHandleState | null>(
    operation ? operationToFormState(operation) : null
  );
  const [groundStations, setGroundStations] = useState<GroundStation[]>([]);
  const [procedures, setProcedures] = useState<Procedure[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);

  useEffect(() => {
    getProcedureAsync();
    getResourcesAsync();
    if (handleType === OperationDialogActionName.edit && formDataState) {
      updateGs(formDataState.satelliteID);
    }
  }, []);

  const submit = async (
    e: React.FormEvent<HTMLInputElement>,
    { formData }: any
  ) => {
    e.preventDefault();
    const handleParams = handleRequestBody(formData, handleType);
    if (!handleParams) return;
    if (handleType === OperationDialogActionName.create) {
      await createOperationActivity(handleParams as CreateOaParams);
    } else if (handleType === OperationDialogActionName.edit && operation) {
      await editOperationActivity(
        operation?.uuid,
        handleParams as PatchOaParams
      );
    }
    await getAllOperationActivities();
    handleClose();
  };

  const getProcedureAsync = async () => {
    const p = await getProcedures();
    setProcedures(p);
  };

  const getResourcesAsync = async () => {
    const r = await getResources();
    setResources(r);
  };

  const updateGs = async (satID: string) => {
    try {
      const gs = await fetchGroundStationBySatellite(Number(satID));
      await setGroundStations(gs);
    } catch (error) {
      console.log(error);
    }
  };

  const startTimeToString = (formData: OaHandleState): OaHandleState => {
    return {
      ...formData,
      taskSetting: {
        ...formData.taskSetting,
        ...((formData.taskSetting.startTime && {
          startTime:
            typeof formData.taskSetting.startTime === "string"
              ? (new Date(
                  formData.taskSetting.startTime
                ).toISOString() as ISODateString)
              : ((
                  formData.taskSetting.startTime as unknown as Date
                ).toISOString() as ISODateString)
        }) ||
          {})
      },
      ...((formData.procedureExecutionSetting && {
        procedureExecutionSetting: {
          ...formData.procedureExecutionSetting,
          ...((formData.procedureExecutionSetting.startTime && {
            startTime:
              typeof formData.procedureExecutionSetting.startTime === "string"
                ? (new Date(
                    formData.procedureExecutionSetting.startTime
                  ).toISOString() as ISODateString)
                : ((
                    formData.procedureExecutionSetting
                      .startTime as unknown as Date
                  ).toISOString() as ISODateString)
          }) ||
            {})
        }
      }) ||
        {})
    };
  };

  const handleOnChange = async ({ formData }: { formData: OaHandleState }) => {
    if (
      formData.satelliteID &&
      formData.satelliteID !== formDataState?.satelliteID
    ) {
      await updateGs(formData.satelliteID);
    }

    const updatedFormData = startTimeToString(formData);
    setFormDataState(updatedFormData);
  };

  return (
    <DialogContent>
      <SuspenseMutation>
        {({ loading, action }) => {
          return (
            <Form
              formData={formDataState || {}}
              id={"create-operation-activity"}
              schema={generateOaHandleSchema(
                satelliteInstances,
                groundStations,
                procedures,
                resources,
                handleType
              )}
              uiSchema={OperationActivityHandleUISchema}
              onSubmit={(form: any, e: React.FormEvent<HTMLInputElement>) => {
                return submit(e, form);
              }}
              disabled={loading}
              onChange={handleOnChange}
              validate={handleFormValidator}
            >
              <Flex justifyContent={"flex-end"}>
                <CancelButton
                  onClick={(e: React.MouseEvent) => handleClose()}
                  mr={2}
                >
                  Cancel
                </CancelButton>
                <SaveButton type="submit" disabled={loading}>
                  Save
                </SaveButton>
              </Flex>
            </Form>
          );
        }}
      </SuspenseMutation>
    </DialogContent>
  );
};
