import React, { useCallback } from "react";
import { Flex } from "primitives";
import { Form } from "app/shared";
import {
  uploadTLESchema,
  uploadTLEUISchema
} from "app/passageBooker/models/schema";

export const UploadTLEForm = () => {
  const validator = (formData: any, errors: any) => {
    console.log(formData, errors);
    return true;
  };

  const submit = useCallback((e, formData) => {
    console.log(e, formData);
  }, []);

  const onChange = useCallback((form) => {
    console.log(form);
  }, []);

  const formData = {};

  return (
    <Flex flexDirection="column" bg="fill.0" p={5}>
      <Form
        validate={(formData: any, errors: any) => validator(formData, errors)}
        formData={formData}
        schema={uploadTLESchema}
        uiSchema={uploadTLEUISchema}
        disabled={false}
        onSubmit={(form: any, e: any) => submit(e, form.formData)}
        onChange={(form: any) => onChange(form)}
        children={undefined}
      ></Form>
    </Flex>
  );
};
