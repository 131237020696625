import { Passage as MockPassage } from "../models";

const GROUNDSTATIONS = [
  "Fino Mornasco",
  "TestGS",
  "Montijo",
  "Alcochete",
  "Setubal",
  "Ireland 1",
  "Bahrain 1",
  "Cape Town 1",
  "Hawaii 1",
  "Ohio 1",
  "Oregon 1",
  "Stockholm 1",
  "Barreiro",
  "AWSTest",
  "AWSTestGs",
  "Quito",
  "Manta",
  "AstralintuTestGs",
  "AST-GS1",
  "IE-01",
  "newGS"
];

const SATELLITES = [
  "ION",
  "SATSIM",
  "ION-SCV002",
  "ION-SCV003",
  "ION-SCV004",
  "ION-SCV005",
  "ION-SCV006",
  "ION-SCV007",
  "ION-SCV008",
  "ION-SCV009",
  "ION-SCV010",
  "ION-SCV011",
  "ION-SCV012",
  "ION-SCV013",
  "ION-SCV014",
  "ION-SCV015"
];
const PROVIDERS = ["AWS", "LEAFSPACE"];
const start_time = "2024-01-01 10:23:10+00:00";
const end_time = "2024-12-01 10:31:51+00:00";
const numPassages = 15000;

type RandomValueType = "number" | "int" | "element";

const getRandomValue = (
  type: RandomValueType,
  min?: number,
  max?: number,
  array?: any[]
) => {
  if (type === "number") {
    return min !== undefined && max !== undefined
      ? Math.random() * (max - min) + min
      : 0;
  } else if (type === "int") {
    return min !== undefined && max !== undefined
      ? Math.floor(Math.random() * (max - min + 1)) + min
      : 0;
  } else if (type === "element") {
    return array && array.length > 0
      ? array[Math.floor(Math.random() * array.length)]
      : null;
  }
  return null;
};

function createMockPassages(
  groundStations: string[],
  satellites: string[],
  providers: string[],
  from: string,
  to: string,
  numOfMockPassages: number
): MockPassage[] {
  const startTime = new Date(from);
  const endTime = new Date(to);
  const timeRange = endTime.getTime() - startTime.getTime();

  const getRandomTimeInRange = (): string => {
    const randomOffset = getRandomValue("number", 0, timeRange) as number;
    return new Date(startTime.getTime() + randomOffset).toISOString();
  };

  const createMockPassage = (id: number): MockPassage => {
    const passageStartTime = getRandomTimeInRange();
    // passage duration should be between 2 - 10 mins
    const passageDuration = getRandomValue(
      "int",
      2 * 60 * 1000,
      10 * 60 * 1000
    ) as number;
    const passageEndTime = new Date(
      new Date(passageStartTime).getTime() + passageDuration
    ).toISOString();

    return {
      start_time:
        passageStartTime < passageEndTime ? passageStartTime : passageEndTime,
      end_time:
        passageStartTime < passageEndTime ? passageEndTime : passageStartTime,
      gs: getRandomValue(
        "element",
        undefined,
        undefined,
        groundStations
      ) as string,
      max_el: getRandomValue("number", 10, 90).toFixed(2),
      sat: getRandomValue(
        "element",
        undefined,
        undefined,
        satellites
      ) as string,
      provider_info: {
        provider: getRandomValue(
          "element",
          undefined,
          undefined,
          providers
        ) as string
      },
      is_editable: getRandomValue("int", 0, 1) as number,
      priority: getRandomValue("number", 1, 10).toFixed(2),
      is_optimal: getRandomValue("int", 0, 1) as number,
      passageID: id
    };
  };

  return Array.from({ length: numOfMockPassages }, (_, id) =>
    createMockPassage(id + 1)
  );
}

export const mockPassages = createMockPassages(
  GROUNDSTATIONS,
  SATELLITES,
  PROVIDERS,
  start_time,
  end_time,
  numPassages
);
