import React, { useReducer, useEffect } from "react";
import { Heading, Flex } from "primitives";
import { BackButton } from "components";
import {
  PlanningSystemTimeline,
  DrawerContent
} from "app/planningSystem/containers";
import { OperationActivityDialog } from "app/planningSystem/containers";
import { TimelineItemDialog } from "./Timeline/TimelineItemDialog";
import { NextEvents } from "./NextEvents";
import styled from "styled-components";
import { NavigationDrawer } from "components";
import {
  initialState,
  reducer,
  PlanningSystemContext
} from "app/planningSystem/context";
import {
  ContextActionsNames,
  PlanningSystemProps
} from "app/planningSystem/models";

const Container = styled("div")`
  background-color: #2e4162;
  padding: 20px 10px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
`;
const PlanningSystemRoot = styled("div")`
  display: flex;
  flex-flow: column;
  height: 100%;
`;
const HeightFiller = styled("div")`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const PlanningSystem = ({
  getTimeline,
  getAllPassages,
  getCalendarTimeline,
  getAllOperationActivities
}: PlanningSystemProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    navigationDrawerVisible,
    contextMenu,
    selectedSatellites,
    timelineWindow
  } = state;

  useEffect(() => {
    getAllOperationActivities();
    getTimelineAsync();
  }, []);

  useEffect(() => {
    if (selectedSatellites.length > 0) {
      getAllPassages(
        selectedSatellites.map((s) => Number(s)),
        timelineWindow.startTime,
        timelineWindow.endTime
      );
    }
  }, [selectedSatellites]);

  const handleNavigationDrawer = (show = navigationDrawerVisible) => {
    dispatch({
      type: ContextActionsNames.navigationDrawerVisible,
      payload: !show
    });
  };

  const getTimelineAsync = async (
    startTime = timelineWindow.startTime,
    endTime = timelineWindow.endTime
  ) => {
    const timelines = await getTimeline({
      startTime,
      endTime
    });
    if (timelines) {
      getCalendarTimeline(timelines);
    }
  };

  const dataProvider = async (begin?: Date, end?: Date) => {
    if (begin && end) {
      await dispatch({
        type: ContextActionsNames.timelineWindow,
        payload: { startTime: begin, endTime: end }
      });
      await getTimelineAsync(begin, end);
      if (selectedSatellites.map((s) => Number(s)).length > 0)
        await getAllPassages(
          selectedSatellites.map((s) => Number(s)),
          begin,
          end
        );
    }
  };

  return (
    <PlanningSystemContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      <PlanningSystemRoot data-testid={"planning-system-root"}>
        <Heading display={1} marginBottom={4}>
          Planning System
        </Heading>
        <Container>
          <Heading display={2} marginBottom={4}>
            Timeline
          </Heading>
          <HeightFiller>
            <Flex justifyContent={"end"} mb={1} alignItems={"start"}>
              <BackButton onClick={() => handleNavigationDrawer()}>
                Operation Activity Manager
              </BackButton>
            </Flex>
            {state.selectedTimeline && (
              <NextEvents selectedTimeline={state.selectedTimeline} />
            )}
            <PlanningSystemTimeline dataProvider={dataProvider} />
          </HeightFiller>
        </Container>
        <NavigationDrawer
          visible={navigationDrawerVisible}
          onClose={handleNavigationDrawer}
          heading={"Operation activity manager"}
        >
          <DrawerContent />
        </NavigationDrawer>
        <OperationActivityDialog />
        <TimelineItemDialog />
        {contextMenu}
      </PlanningSystemRoot>
    </PlanningSystemContext.Provider>
  );
};
